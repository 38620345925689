import React from "react";

const WithdrawlWithPayoneer = ({ isOpen, onClose }) => {
  if (!isOpen) return null;
  return (
    <div style={{"paddingTop":"20px", "paddingBottom": "20px"}} className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
      <div className="relative bg-white rounded-lg shadow-xl p-5 w-full mx-2 sm:w-1/2">
        <div className="flex justify-between items-center border-b pb-3">
          <h3 className="text-xl font-medium">Withdraw With Payoneer</h3>
          <button
            onClick={onClose}
            className="text-white bg-red-500 hover:bg-red-700 font-bold py-2 px-4 rounded"
          >
            Close
          </button>
        </div>
        <div className="mt-4">
          <p className="text-gray-700">
            <strong>To Receive Your Payment, Please Follow These Steps:</strong>
          </p>
          <ol className="list-decimal pl-6 mt-2 text-gray-700">
            <li>Create a Payoneer Account: Sign up for a Payoneer account <a href="https://www.payoneer.com/" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">here</a>.</li>
            <li>Send Us Your Details: After creating your Payoneer account, email us at <strong>𝗰𝗼𝗻𝘁𝗮𝗰𝘁@𝗰𝗼𝗻𝘃𝗲𝗿𝘁𝗶𝘅𝗼.𝗰𝗼𝗺</strong> with:
              <ul className="list-disc pl-6 mt-2">
                <li>Your registered email address with Convertix.</li>
                <li>A screenshot of your Convertix affiliated dashboard showing your account details and desired withdrawal amount.</li>
              </ul>
            </li>
          </ol>
          <p style={{"margin-top":"10px"}} className="text-gray-700">
          <strong style={{"margin":"0px"}} className="block mb-2">Payoneer Processing:</strong> Your payment will be processed within 24 hours upon receipt of your Payoneer details and dashboard screenshot. Please ensure all information is accurate to avoid delays.
          </p>
          <p style={{"margin-top":"10px"}} className="text-gray-700">
          <strong style={{"margin":"0px"}} className="block mb-2">Why Payoneer?</strong> Payoneer offers fast and secure payment processing, ensuring prompt delivery of your commissions.
          </p>
          <p style={{"margin-top":"10px"}} className="text-gray-700">
          <strong style={{"margin":"0px"}} className="block mb-2">Holiday Notice:</strong> Processing times may vary during holidays or weekends.
          </p>
          <p style={{"margin-top":"20px"}} className="text-gray-700">
            Thank you for being our affiliate!
          </p>
        </div>
      </div>
    </div>
  );
};

export default WithdrawlWithPayoneer;
