import './App.css';
import './Loader.css';
import React, { useEffect } from 'react';
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import ReferralPage from './Refferal';
import SignUpPage from './Singup';
import AdminDashboard from './AdminDashboard';

const App = () => {
  useEffect(() => {
    // Redirect to another site when visiting "/affiliate"
    if (window.location.pathname === "/") {
      window.location.href = 'https://app.convertixo.com/login';
    }
  }, []);

  const appRouter = createBrowserRouter([
    {
      path: "/",
      element: <div className="loader-container">
        <div className="loader"></div>
      </div>
    },
    {
      path: "/affiliate",
      element: <SignUpPage />,
    },
    {
      path: "/affiliate/dashboard",
      element: <ReferralPage />,
    },
    {
      path: "/admin/dashboard",
      element: <AdminDashboard />,
    },
  ]);

  return (
    <div>
      <RouterProvider router={appRouter} />
    </div>
  );
}

export default App;
