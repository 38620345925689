import React, { useState, useEffect, useRef } from "react";
import {
  getReferralInfo,
  fetchUserInfo,
  changePassword,
  checkCountry,
  getExchangeRate,
  tofix,
} from "./apiService";
import WithdrawalModal from "./Withdrawl";
import WithdrawlWithPayoneer from "./WithdrawlWithPayoneer";
import swal from 'sweetalert';
import { useNavigate } from "react-router-dom";

const ReferralPage = () => {
  const ChangedPassword = useRef(null);
  const [earnedMoney, setEarnedMoney] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [referralCode, setReferralCode] = useState("123");
  const [username, setUsername] = useState(null);
  const [email, setEmail] = useState(null);
  const [country, setCountry] = useState("");
  const [exchangeRate, setExchangeRate] = useState(1); // Assuming 1 as default
  const [userId, setUserId] = useState("");
  const [phone, setPhone] = useState("");
  const [city, setCity] = useState("");
  const [address1, setAddress1] = useState("");
  const [pincode, setPincode] = useState("");
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      checkCountry()
        .then((countryData) => {
          setCountry(countryData);
          if (countryData === "India") {
            getExchangeRate().then(setExchangeRate).catch(console.error);
          }
        })
        .catch(console.error);

      getReferralInfo(token)
        .then((data) => {
          if (country === "India") {
            const s = tofix(data[0].moneyEarned);
            setEarnedMoney(s);
          } else {
            const s = data[0].usMoney;
            setEarnedMoney(s);
          }
          setReferralCode(data[0].referralCode);
        })
        .catch(console.error);
    }
  }, [country]); // Adding `country` as a dependency to re-run this effect when `country` changes

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      fetchUserInfo(token)
        .then((data) => {
          //console.log(data);
          setUsername(data.name);
          setEmail(data.email);
          setUserId(data.id);
          setAddress1(data.address1);
          setPhone(data.phone);
          setPincode(data.pincode);
          setCity(data.city);
         
        })
        .catch((err) => {
          swal("Error!", err, "error"); 
        });
      }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/affiliate");
    }
  }, [navigate]);
 

  const referralLink = `https://app.convertixo.com/register?uniqueCode=${encodeURIComponent(
    referralCode
  )}`;

  const handleChangePassword = async () => {
    setLoading(true);
    const newPassword = ChangedPassword.current.value;
    const token = localStorage.getItem("token");
    if (!newPassword.trim()) {
      swal("Validation!","Please enter a new password", "warning"); 
      return;
    }
    try {
      await changePassword(token, newPassword);
      console.log("Password changed successfully");
      // Clear the input field after successful password change
      if(ChangedPassword && ChangedPassword.current && ChangedPassword.current.value){
        ChangedPassword.current.value = "";
      }
      setLoading(false);
      swal("Success!", "Password Changed successfully", "success"); 
    } catch (error) {
      setLoading(false);
      console.error("Failed to change password:", error);
      if(error && error.response && error.response.data){
        swal("Failed to Update!", error.response.data, "error"); 
      }
      else if(error && error.response && error.response.data && error.response.data.message){
        swal("Failed to Update!", error.response.data.message, "error"); 
      }
      else{
        swal("Error!", "Failed to Update password", "error"); 
      }
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    navigate("/affiliate"); // Redirect to the login/signup page
  };

  const handleOnclose = () => {
    setIsOpen(false);
    window.location.reload();
  }


  return (
    <>
    {isLoading ? 
      <div className="loader-container">
        <div className="loader"></div>
      </div>
      :
    <div className="flex flex-col items-center justify-center h-screen bg-gray-300">
      <div className="w-full max-w-2xl h-screen p-6 bg-gray-100 shadow-lg rounded-lg">
        <header className="bg-black text-white text-center rounded-t-lg p-4 shadow">
          <h1 className="text-3xl">WELCOME TO CONVERTIXO</h1>
          <p className="mt-2 text-xl">Share your referral link & Earn More!</p>
        </header>

        <div className="mt-4 p-4">
          {/* User Information Display */}
          <div className="mb-4">
            <div className="text-xl font-bold text-gray-700 tracking-wide">
              Name
            </div>
            <div className="text-xl text-gray-900">{username}</div>
          </div>

          <div className="mb-4">
            <div className="text-xl font-bold text-gray-700 tracking-wide">
              Email
            </div>
            <div className="text-xl text-gray-900">{email}</div>
          </div>

          {/* Password Change Section */}
          <div className="flex items-center justify-between mb-4">
            <div className="w-1/2 mr-2">
              <input
                id="newPassword"
                placeholder="Enter your New Password"
                type="password"
                className="mt-1 p-2 w-full border border-gray-300 rounded"
                ref={ChangedPassword} // Apply useRef to the password input
              />
            </div>
            <button
              onClick={handleChangePassword}
              className="bg-blue-400 text-white py-2 w-1/3 rounded hover:bg-blue-600 focus:outline-none focus:bg-blue-700 transition ease-in duration-200 text-center text-base font-semibold shadow-md"
            >
              Change Password
            </button>
          </div>
          

          {/* Earned Money and Withdraw Section */}
          <div className="flex items-center justify-between mb-4">
            <div>
              <div className="text-sm font-bold text-gray-700 tracking-wide">
                Earned Money
              </div>
              <div className="text-xl text-green-400">
                {country === "India"
                  ? `${earnedMoney} INR`
                  : `$ ${earnedMoney}`}
              </div>
            </div>
            <button
              // Placeholder for withdraw functionality
              onClick={() => setIsOpen(true)}
              className="bg-blue-400 w-1/3 text-white py-2 px-6 rounded hover:bg-blue-700 focus:outline-none focus:bg-blue-700 transition ease-in duration-200 text-center text-base font-semibold shadow-md"
            >
              Withdraw
            </button>
            {country==="India"? (
              <WithdrawalModal
                isOpen={isOpen}
                onClose={handleOnclose}
                balance={earnedMoney}
                name={username}
                email={email}
                userId={userId}
                phone={phone}
                city={city}
                pincode={pincode}
                address={address1}
              />
            ) : (
              <WithdrawlWithPayoneer
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
              />
            )}
          </div>

          {/* Affiliate Link Section */}
          <div className="text-center p-4 mt-8 mb-4 bg-blue-100 rounded-lg">
            <h2 className="text-xl font-bold text-black">
              Get your Affiliate link here
            </h2>
            <div style={{margin:"10px"}}><a href="#">{`${referralLink}`}</a></div>
          </div>
          
          {/* Logout Button */}
          <button
            onClick={handleLogout}
            className="mt-4 bg-red-500 text-white w-full py-2 px-4 rounded hover:bg-red-600 focus:outline-none focus:bg-red-700 transition ease-in duration-200 text-center text-base font-semibold shadow-md"
          >
            Logout
          </button>
        </div>
      </div>
    </div>
    }
    </>
  );
};

export default ReferralPage;
