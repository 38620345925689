import React, { useState,useEffect, useRef } from "react";
import axios from "axios";
import swal from 'sweetalert';

const WithdrawalModal = ({
  isOpen,
  onClose,
  balance,
  name,
  email,
  userId,
  phone,
  city,
  pincode,
  address,
}) => {
 // console.log(email);
  const [currentStep, setCurrentStep] = useState(1);
  const [accountTypeSelection, setAccountTypeSelection] = useState("");
  const [userDetails, setUserDetails] = useState({
    phone,
    city,
    pincode,
    address,
    accountType: "",
    accountNumber: "",
    ifsc: "",
    upi: "",
  });
  const [withdrawalAmount, setWithdrawalAmount] = useState("");
  const bene = useRef(userId + Date.now());
   console.log(bene.current);
  useEffect(() => {
    setUserDetails({
      phone: phone || "",
      city: city || "",
      pincode: pincode || "",
      address: address || "",
      accountType: userDetails.accountType,
      accountNumber: userDetails.accountNumber,
      ifsc: userDetails.ifsc,
      upi: userDetails.upi,
    });
  }, [phone, city, pincode, address]);


  const apiBaseUrl = "https://app.convertixo.com/affiliate"; // Adjust as necessary

  const handleUserDetailsChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const validateUserDetails = () => {
    const {
      phone,
      city,
      pincode,
      address,
      accountType,
      accountNumber,
      ifsc,
      upi,
    } = userDetails;
    const isValidAccountType =
      accountType === "bank_account"
        ? userDetails.accountNumber.trim() && userDetails.ifsc.trim()
        : userDetails.upi.trim();

    return (
      phone.trim() &&
      city.trim() &&
      pincode.trim() &&
      address.trim() &&
      isValidAccountType
    );
  };

  const formatUserDataForSubmission = () => {
    const { accountType, accountNumber, ifsc, upi } = userDetails;
    const data = {
      name: name,
      email: email,
      phone: userDetails.phone,
      city: userDetails.city,
      pincode: userDetails.pincode,
      address1: userDetails.address,
      beneId: bene.current, // Adjust based on how you manage beneficiary IDs
    };

    if (accountType === "bank_account") {
      data.bankAccount = accountNumber;
      data.ifsc = ifsc;
    } else {
      data.vpa = upi; // For UPI
    }

    return data;
  };

  const handleUserDetailSubmission = async () => {
    if (!validateUserDetails()) {
      swal("Missing!", "Please fill all details.", "warning"); 
      return;
    }

    const userData = formatUserDataForSubmission();

    try {
      const token = localStorage.getItem("token");
      await axios.put(
        `${apiBaseUrl}/update-user`,
        {
          email: email,
          ...userData,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const response = await axios.post(
        `${apiBaseUrl}/addBeneficiary`,
        userData
      );
      
      // Assuming the response is handled correctly by your backend
      swal("Success!", "User Details Updated Successfully", "success"); 
      setCurrentStep(currentStep + 1);
    } catch (error) {
      if(error && error.response && error.response.data){
        swal("Failed to Update!", error.response.data, "error"); 
      }
      else if(error && error.response && error.response.data && error.response.data.message){
        swal("Failed to Update!", error.response.data.message, "error"); 
      }
      else{
        swal("Failed to Update!", "Error in updating user details: Provide correct details", "error"); 
      }
      console.error("Error in updating user details:", error);
    }
  };

  const handleWithdrawalSubmission = async () => {
    const amount = parseFloat(withdrawalAmount);
    if (isNaN(amount) || amount <= 0 || amount > balance) {
      swal("Validation!", "Please enter a valid withdrawal amount that is within your balance.", "warning"); 
      return;
    }
   
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${apiBaseUrl}/payout`,
        {
          beneId: bene.current, // Assuming beneId is the same as userId, adjust as necessary
          amount: withdrawalAmount,
          id:userId,
          transferId: userId + "T" + Date.now(), // Example transferId, adjust as necessary
          transferMode:
            userDetails.accountType === "bank_account" ? "banktransfer" : "upi",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      
      swal("Success!", "Withdrawal Successful & refrence ID is"+ " " +response.data.data.data.referenceId, "success"); 
      onClose(); // Close the modal
    } catch (error) {
      if(error && error.response && error.response.data){
        swal("Error!", error.response.data, "error"); 
      }
      else if(error && error.response && error.response.data && error.response.data.message){
        swal("Error!", error.response.data.message, "error"); 
      }
      else{
        swal("Error!", "Error in making withdrawal", "error");
      }
      console.error("Error in making withdrawal:", error);
    }
  };
  //console.log(userDetails.address);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
      <div className="relative bg-white rounded-lg shadow-xl p-5 md:p-10 w-2/3 md:w-1/3">
        {/* Modal Content */}
        <div className="flex justify-between items-center border-b pb-3">
          <h3 className="md:text-2xl text-xl font-bold">Withdrawal Process</h3>
          <button
            onClick={onClose}
            className="text-white bg-red-500 hover:bg-red-700 font-bold py-2 px-4 rounded"
          >
            Close
          </button>
        </div>

        {currentStep === 1 && (
          <div>
            <h1>Provide Your Details</h1>
            <input
              type="text"
              name="phone"
              placeholder="Phone"
              value={userDetails.phone}
              onChange={handleUserDetailsChange}
              className="block w-full p-2 mt-2 border rounded"
            />
            <input
              type="text"
              name="city"
              placeholder="City"
              value={userDetails.city}
              onChange={handleUserDetailsChange}
              className="block w-full p-2 mt-2 border rounded"
            />
            
            <input
              type="text"
              name="pincode"
              placeholder="Pincode"
              value={userDetails.pincode}
              onChange={handleUserDetailsChange}
              className="block w-full p-2 mt-2 border rounded"
            />
            <textarea
              name="address"
              placeholder="Address"
              value={userDetails.address}
              onChange={handleUserDetailsChange}
              className="block w-full p-2 mt-2 mb-4 border rounded"
            ></textarea>
            <select
              name="accountType"
              value={userDetails.accountType}
              onChange={(e) => {
                setAccountTypeSelection(e.target.value);
                handleUserDetailsChange(e);
              }}
              className="block w-full p-2 mt-2 border rounded"
            >
              <option value="">Select Account Type</option>
              <option value="bank_account">Bank Account</option>
              <option value="upi">UPI</option>
            </select>
            {accountTypeSelection === "bank_account" && (
              <div>
                <input
                  type="text"
                  name="accountNumber"
                  placeholder="Account Number"
                  value={userDetails.accountNumber}
                  onChange={handleUserDetailsChange}
                  className="block w-full p-2 mt-2 border rounded"
                />
                <input
                  type="text"
                  name="ifsc"
                  placeholder="IFSC Code"
                  value={userDetails.ifsc}
                  onChange={handleUserDetailsChange}
                  className="block w-full p-2 mt-2 border rounded"
                />
              </div>
            )}
            {accountTypeSelection === "upi" && (
              <input
                type="text"
                name="upi"
                placeholder="UPI ID"
                value={userDetails.upi}
                onChange={handleUserDetailsChange}
                className="block w-full p-2 mt-2 border rounded"
              />
            )}
            <button
              onClick={handleUserDetailSubmission}
              className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
            >
              Next
            </button>
          </div>
        )}

        {currentStep === 2 && (
          <div>
            <h2>Withdrawal Details</h2>
            <p>Balance: INR {balance}</p>
            <input
              type="number"
              placeholder="Amount"
              onChange={(e) => setWithdrawalAmount(e.target.value)}
              className="block w-full p-2 mt-2 border rounded"
            />
            <div className="flex justify-between mt-4">
              <button
                onClick={() => setCurrentStep(1)}
                className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-700"
              >
                Back
              </button>
              <button
                onClick={handleWithdrawalSubmission}
                className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-700"
              >
                Submit
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default WithdrawalModal;
