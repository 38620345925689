import axios from "axios";

const API_BASE_URL = "https://app.convertixo.com/affiliate"; // Adjust this URL to match your Express server's address

// Function to register a new user
export const registerUser = async (userData) => {
     console.log("123")
     console.log(userData);
  try {
 
    const response = await axios.post(
      `https://app.convertixo.com/affiliate/register`,
      userData
    );
    console.log(response);
    return response;
  } catch (error) {
    console.error("Error during registration:", error);
    throw error;
  }
};

// Function to log in a user
export const loginUser = async (credentials) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/login`, credentials);
    return response;
  } catch (error) {
    console.error("Error during login:", error);
    throw error;
  }
};

// Function to edit user password
export const editUserPassword = async (newPassword, token) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/edit-password`,
      { password: newPassword },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error changing password:", error);
    throw error;
  }
};

// Function to get referral info
export const getReferralInfo = async (token) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/referral`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching referral information:", error);
    throw error;
  }
};
export const checkCountry = async () => {
  try {
    const ipAddress = await axios.get("https://api.ipify.org?format=json");
    const ip = ipAddress.data.ip
    console.log(ip);
    const api = await axios.get(`https://api.ipgeolocation.io/ipgeo?apiKey=76e005e01cd9489ea106990b2305b296&ip=${ip}`);
    console.log(api.data.country_name);
    return api.data.country_name
  }
  catch (error) {
    return null;
  }

}
export const tofix = (value)=>
{
  const s = Number(value).toFixed(2);
  return s;
}

export async function getExchangeRate() {
  try {
    const response = await fetch(
      "https://api.exchangerate-api.com/v4/latest/USD"
    );
    const data = await response.json();
    const exchangeRate = data.rates.INR;
    return exchangeRate;
  } catch (error) {
    console.error("Error:", error);
  }
}
export const fetchUserInfo = async (token) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/user-info`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(`Network response was not ok: ${error}`);
  }
};

// Function to change password using Axios
export const changePassword = async (token, newPassword) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/edit-password`,
      { password: newPassword },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(
      `HTTP error! status: ${error.response?.status}: ${error.message}`
    );
  }
};
