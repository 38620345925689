import React, { useState } from "react";
import axios from "axios";
import swal from 'sweetalert';

const FORGOT = ({ isOpen, onClose, setLoading }) => {
  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  
  const endpoint = "https://app.convertixo.com/affiliate/forgot-password";

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(endpoint, {
        email,
        newPassword,
      });
        setLoading(false);
        onClose()
        if (response.data)  swal("Success!", "Password Updated Sucessfully", "success");
      }
      catch (error) {
        setLoading(false);
        console.log(`Error! ${error}`);
        if(error && error.response && error.response.data){
          swal("Failed!", error.response.data, "error"); 
        }
        else if(error && error.response && error.response.data && error.response.data.message){
          swal("Failed!", error.response.data.message, "error"); 
        }
        else{
          swal("Failed!", "Failed to update", "error"); 
        } 
      };
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
      <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
        <div className="absolute top-0 right-0 pt-4 pr-4">
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500"
            aria-label="close"
          >
            <span className="text-2xl">&times;</span>
          </button>
        </div>
        
        <div className="mt-3 text-center">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Reset Password
          </h3>
          <div className="mt-2 px-7 py-3">
            <form className="bg-white" >
              <input
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                className="mt-2 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                required
              />
              <input
                type="password"
                name="newPassword"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                placeholder="New Password"
                className="mt-2 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                required
              />
              <div className="items-center px-4 py-3">
                <button
                  onClick={handleSubmit}
                  className="px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300"
                >
                  Reset Password
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FORGOT;
