import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import swal from 'sweetalert';
import './Loader.css';

const AdminDashboard = () => {
  const [initialCommission, setInitialCommission] = useState("");
  const [recurringCommission, setRecurringCommission] = useState("");
  const [loading, setLoading] = useState(true); // State for loader
  const endpoint = "https://app.convertixo.com/affiliate/admin";
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch commission data on component mount
    axios
      .get(endpoint)
      .then((response) => {
        console.log(response.data);
        const { initial_commission, recurring_commission } = response.data;
        console.log(initial_commission);
        setInitialCommission(initial_commission);
        setRecurringCommission(recurring_commission);
        setLoading(false); // Set loading to false when data is fetched
      })
      .catch((error) => {
        console.error("Failed to fetch commission data:", error);
        setLoading(false); // Set loading to false in case of error too
      });
  }, []); // endpoint is added as a dependency here for completeness, though it's not strictly necessary since it's a constant in this context.

  useEffect(() => {
    const token = localStorage.getItem("token");
    const email = localStorage.getItem("email");
    if (!token) {
      navigate("/affiliate");
    }
    else if (!email) {
      localStorage.removeItem("token");
      navigate("/affiliate");
    }
    else if(email.toLowerCase() !== "adminaffiliate@gmail.com"){
      swal("UnAuthorization", "You have no permession to access this page", "error"); 
      navigate("/affiliate");
    }
  }, [navigate]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setInitialCommission(initialCommission);
    setRecurringCommission(recurringCommission);
    // Update commission data
    axios
        .post(endpoint, {
        email:"adminAffiliate@gmail.com",
        initialCommission,
        recurringCommission,
      })
      .then((response) => {
        console.log("Commission data updated successfully:", response.data);
        // Optionally, you could refetch the commission data here to ensure the UI is in sync with the backend.
      })
      .catch((error) =>
        console.error("Failed to update commission data:", error)
      );
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    navigate("/affiliate");
  };

  return (
    <div className="text-3xl mt-4">
      {loading ? ( // Display loader while loading data
       <div className="loader-container">
        <div className="loader"></div>
      </div>
      ) : (
        <form
          onSubmit={handleSubmit}
          className="space-y-4 w-1/2 h-1/2 p-10 bg-slate-300 mx-auto"
        >
          <div style={{display:"flex"}}>
          <h1 className="mx-auto">Convertixo</h1>
          <button
            onClick={handleLogout}
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Logout
          </button>
          </div>
          
          <div>
            <label
              htmlFor="initialCommission"
              className="block text-sm font-medium text-gray-700"
            >
              Initial Commission
            </label>
            <input
              type="text"
              name="initialCommission"
              id="initialCommission"
              value={initialCommission}
              onChange={(e) => setInitialCommission(e.target.value)}
              className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>

          <div>
            <label
              htmlFor="recurringCommission"
              className="block text-sm font-medium text-gray-700"
            >
              Recurring Commission
            </label>
            <input
              type="text"
              name="recurringCommission"
              id="recurringCommission"
              value={recurringCommission}
              onChange={(e) => setRecurringCommission(e.target.value)}
              className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>

          <button
            type="submit"
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Update
          </button>
        </form>
      )}
    </div>
  );
};

export default AdminDashboard;
