import React, { useState, useRef, useEffect } from "react";
import './Loader.css';
import { useNavigate } from "react-router-dom";
import { loginUser, registerUser } from "./apiService"
import FORGOT from "./forgotPassword";
import swal from 'sweetalert';

const SignUpPage = () => {
  const [login, Setlogin] = useState(true)
  const [isOpen, Setisopen] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [isChecked, setIsChecked] = useState(false);
 
  const navigate = useNavigate();
  let nameRef = useRef(null);
  let emailRef = useRef(null);
  let passwordRef = useRef(null);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/affiliate/dashboard");
    }
  }, [navigate]);
  
const handleFormSubmit = async (e) => {
  e.preventDefault(); // Prevent default form submission behavior
  setLoading(true);
  const name = nameRef.current?.value;
  const email = emailRef.current.value;
  const password = passwordRef.current.value;

  if (login) {
    // Handle login
    try {
      var response = await loginUser({ email, password });
      console.log("Login successful", response);
      if (response.data.jwt)
      {
        localStorage.setItem('token', response.data.jwt);
        localStorage.setItem('email', email.toLowerCase());
        setLoading(false);
        swal("Success!", "Logged in successfully", "success");  
       // var n = response.name, m = response.email;
        console.log(response);
        if (email.toLowerCase() === "adminaffiliate@gmail.com")
          navigate("/admin/dashboard");
        else
         navigate("/affiliate/dashboard");
        }
      
    } catch (error) {
      setLoading(false);
      console.error("Login failed", error.response.data);
      if(error && error.response && error.response.data){
        swal("Failed to Login!", error.response.data, "error"); 
      }
      else if(error && error.response && error.response.data && error.response.data.message){
        swal("Failed to Login!", error.response.data.message, "error"); 
      }
      else{
        swal("Failed!", "Failed to Login", "error"); 
      } 
    }
  } else {
    // Handle registration
    try {
      if (!isChecked) {
        setLoading(false);
        swal("Alert!", "Please accept term and conditions", "warning");
      }
      else{
        const response = await registerUser({ name, email, password });
        console.log("Registration successful", response);
        setLoading(false);
        // Perform post-registration actions (e.g., redirect, update UI)
        if (response.data.jwt) {
          // localStorage.setItem("token", response.jwt);
          swal("Verify!", "Verify your email before login", "warning"); 
        }
        Setlogin(true);
        navigate('/affiliate');
      }
    } catch (error) {
      console.error("Registration failed");
      setLoading(false);
      if(error && error.response && error.response.data){
        swal("Failed to Register!", error.response.data, "error"); 
      }
      else if(error && error.response && error.response.data && error.response.data.message){
        swal("Failed to Register!", error.response.data.message, "error"); 
      }
      else{
        swal("Failed!","Failed to Register!", "error"); 
      }
    }
  }
};

   const handleLogin=(e)=>{
     Setlogin(!login)
   }

  const handleCheckBox = (e) => {
    setIsChecked(e.target.checked)
  }

  return (
    <>
    {isLoading ? 
      <div className="loader-container">
        <div className="loader"></div>
      </div>
      :
    <div className="flex items-center justify-center  mx-auto h-screen bg-white">
      <div className="w-full h-screen max-w-3xl p-8  bg-white rounded-lg shadow-lg">
        <div className="flex flex-col items-center justify-center p-4 bg-black rounded-t-lg">
          <h1 className="text-2xl font-bold text-white">
            {login ? "Affiliate Sign In" : "Affiliate Sign Up"}
          </h1>
          <img
            src="/cropped-feviconlogo.png"
            alt="Convertixo Logo"
            className="mt-3 w-full h-32 object-contain"
          />
        </div>
        <form className="mt-4 w-full" onSubmit={(e) => e.preventDefault()}>
          {/* Name Field */}
          {!login && (
            <div className="flex flex-col items-center">
              <input
                className="w-2/3 px-3 py-2 leading-tight text-gray-700 border rounded appearance-none focus:outline-none focus:shadow-outline"
                id="name"
                type="text"
                placeholder="Enter your name"
                ref={nameRef}
              />
            </div>
          )}
          {/* Email Field */}
          <div className="flex flex-col items-center mt-4">
            <input
              className="w-2/3 px-3 py-2 leading-tight text-gray-700 border rounded appearance-none focus:outline-none focus:shadow-outline"
              id="email"
              type="email"
              placeholder="Enter your email"
              ref={emailRef}
            />
          </div>

          {/* Password Field */}
          <div className="flex flex-col items-center mt-4">
            <input
              className="w-2/3 px-3 py-2 leading-tight text-gray-700 border rounded appearance-none focus:outline-none focus:shadow-outline"
              id="password"
              type="password"
              placeholder="Enter password"
              ref={passwordRef}
            />
          </div>
          {!login &&
          (<div className="flex flex-col items-center mt-4">
            <div className="checkbox-wrapper">
              <label>
                <input type="checkbox" checked={isChecked} onChange={handleCheckBox}/>
                <span>  I accept the <a href="https://convertixo.com/terms-conditions/" style={{color:"#0000EE"}}>term and conditions</a></span>
              </label>
            </div>
          </div> )
          }

          {login && (
            <div className="flex items-center mx-auto">
              <p className="text-sm font-bold text-blue-500 align-baseline hover:text-blue-800 cursor-auto mt-2 mx-auto"
                onClick={()=>Setisopen(true)}
              >
                Forgot Password?
              </p>
              <FORGOT isOpen={isOpen} onClose={()=>Setisopen(false)} setLoading = {setLoading}/>
            </div>
          )}
          <div className="flex  flex-col items-center justify-between mt-4">
            <button
              className="px-4 py-2 font-bold w-1/2 text-white bg-blue-500 rounded hover:bg-blue-700 focus:outline-none focus:shadow-outline"
              type="button"
              onClick={handleFormSubmit}
            >
              {login ? "Sign In" : "Sign Up"}
            </button>
            <p
              className="text-sm font-bold text-blue-500 align-baseline hover:text-blue-800 cursor-auto mt-2"
              onClick={handleLogin}
            >
              {login
                ? "Not Have an account?Sign Up"
                : "Have an account? Sign in"}
            </p>
          </div>
        </form>
      </div>
    </div>
    }
    </>
  );
};

export default SignUpPage;
